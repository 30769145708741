@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Karla";
  src: url("/public/fonts/Karla/Karla-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Karla", Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/****************** Transaction scrollwheel ******************/
/* For Safari and Chrome */
.scrollable-div::-webkit-scrollbar {
  width: 12px;
}

.scrollable-div::-webkit-scrollbar-track {
  background: transparent;
}

.scrollable-div::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.scrollable-div::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For Firefox */
.scrollable-div {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}
/****************** Transaction scrollwheel ******************/

/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}

/* For Firefox */
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-50px);
    opacity: 0;
  }
}

.slide-in {
  animation: slideIn 0.5s ease-out forwards;
}

.slide-out {
  animation: slideOut 0.5s ease-in forwards;
}

@keyframes zoomIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.zoom-in-animation {
  animation: zoomIn 0.5s ease-out forwards;
}

@keyframes zoom-out {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.5);
    opacity: 0;
  }
}

.zoom-out-animation {
  animation: zoom-out 0.5s forwards;
}

/* FOR INPUTS */
.relative {
  position: relative;
  /* margin-bottom: 1.5rem; */
}

.floating-input {
  background: transparent;
  padding: 0.75rem 0.5rem;
  border-radius: 0.5rem;
}

.floating-label {
  position: absolute;
  top: 1.4rem;
  left: 0.5rem;
  font-size: 0.875rem;
  color: gray;
  pointer-events: none;
  transition: all 0.2s ease;
  background: white;
  padding: 0 0.25rem;
}

.floating-input:focus + .floating-label,
.floating-input:not(:placeholder-shown) + .floating-label {
  top: -0.75rem;
  left: -0.1rem;
  font-size: 0.75rem;
  color: #2264e5; /* Color when input is focused or has value */
}

/* FOR LOADING IN GENERAL */

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #3498db;
  animation: dot-flashing 1s infinite linear;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-flashing {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

/* FOR SESSION MODAL */
.modal {
  transform: scale(0);
  opacity: 0;
}

.modal-open {
  transform: scale(1);
  opacity: 1;
  animation: unfold 0.3s forwards;
}

.modal-close {
  animation: fold 0.3s forwards;
}

/* NOTIFICATION FOR REQUEST SESSION */
.alert {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  transition: opacity 0.3s ease;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.alert-error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* NOTIFICATION FOR ADMIN SESSION DESCRIPTION */
@keyframes zoom-in {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes zoom-out {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.8);
    opacity: 0;
  }
}

.modal-sessions-description-open {
  animation: zoom-in 0.3s ease forwards;
}

.modal-sessions-description-close {
  animation: zoom-out 0.3s ease forwards;
}

/* Shimmer for wallet fill button (Student's Course Page) */
.shimmer-effect {
  background-size: 200% 200%;
  background-position: top left;
  animation: shimmer 2s infinite linear;
}

@keyframes shimmer {
  0% {
    background-position: -200% -200%;
  }
  100% {
    background-position: 200% 200%;
  }
}

/* Fade-In Animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.5s ease-out;
}

/* Hide scrollbar but still make the content scrollable */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* for Internet Explorer 10+ */
  scrollbar-width: none; /* for Firefox */
}

/* CUSTOM SCROLLBAR */
/* For Firefox */
.custom-scrollbar {
  scrollbar-width: thin; /* Makes the scrollbar thin */
  scrollbar-color: rgba(126, 126, 126, 0.3) rgba(0, 0, 0, 0.1); /* Thumb and track colors */
}

/* Custom Scrollbar for Webkit-based browsers (Chrome, Safari, etc.) */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Set width of scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); /* Light color for the thumb */
  border-radius: 12px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Track background */
  border-radius: 10px; /* Round corners for the scrollbar track */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4); /* Darker thumb color on hover */
}

/* NEW SUBMIT STATE LOADING (SAFE KEEP FOR FUTURE DEVELOPMENT) */
@keyframes dotAnimation {
  0% {
    content: "";
  }
  33% {
    content: ".";
  }
  66% {
    content: "..";
  }
  100% {
    content: "...";
  }
}

.dots::after {
  content: "Submitting";
  display: inline;
  animation: dotAnimation 0.8s steps(3) infinite;
}
